/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserAdditionalInfoFragment = { id: number, email?: string | null, phone?: string | null, language_abbr: string, newsletter_agreement: boolean, created_at: number, profile?: { user_id: number, first_name?: string | null } | null };

export type UserAdditionalInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserAdditionalInfoQuery = { me?: { id: number, email?: string | null, phone?: string | null, language_abbr: string, newsletter_agreement: boolean, created_at: number, profile?: { user_id: number, first_name?: string | null } | null } | null };


export const UserAdditionalInfoFragmentDoc = `
    fragment UserAdditionalInfo on User {
  id
  email
  phone
  language_abbr
  newsletter_agreement
  created_at
  profile {
    user_id
    first_name
  }
}
    `;
export const UserAdditionalInfoDocument = `
    query UserAdditionalInfo {
  me(is_me: true) {
    ...UserAdditionalInfo
  }
}
    ${UserAdditionalInfoFragmentDoc}`;

export const useUserAdditionalInfoQuery = <
      TData = UserAdditionalInfoQuery,
      TError = unknown
    >(
      variables?: UserAdditionalInfoQueryVariables,
      options?: Omit<UseQueryOptions<UserAdditionalInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserAdditionalInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserAdditionalInfoQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserAdditionalInfo'] : ['UserAdditionalInfo', variables],
    queryFn: fetcher<UserAdditionalInfoQuery, UserAdditionalInfoQueryVariables>(UserAdditionalInfoDocument, variables),
    ...options
  }
    )};

useUserAdditionalInfoQuery.getKey = (variables?: UserAdditionalInfoQueryVariables) => variables === undefined ? ['UserAdditionalInfo'] : ['UserAdditionalInfo', variables];

export const useSuspenseUserAdditionalInfoQuery = <
      TData = UserAdditionalInfoQuery,
      TError = unknown
    >(
      variables?: UserAdditionalInfoQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserAdditionalInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserAdditionalInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserAdditionalInfoQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserAdditionalInfoSuspense'] : ['UserAdditionalInfoSuspense', variables],
    queryFn: fetcher<UserAdditionalInfoQuery, UserAdditionalInfoQueryVariables>(UserAdditionalInfoDocument, variables),
    ...options
  }
    )};

useSuspenseUserAdditionalInfoQuery.getKey = (variables?: UserAdditionalInfoQueryVariables) => variables === undefined ? ['UserAdditionalInfoSuspense'] : ['UserAdditionalInfoSuspense', variables];


useUserAdditionalInfoQuery.fetcher = (variables?: UserAdditionalInfoQueryVariables, options?: RequestInit['headers']) => fetcher<UserAdditionalInfoQuery, UserAdditionalInfoQueryVariables>(UserAdditionalInfoDocument, variables, options);
