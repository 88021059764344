import {
	ConsumerLoanProduct,
	type EstoProductType,
	NonLoanProduct,
} from '@/shared/types';
import { createFileRoute } from '@tanstack/react-router';
import { DEALS_IDS } from '@widgets/deals/list';
import * as z from 'zod';

const dashboardSearchSchema = z.object({
	selectedDeal: z.enum(DEALS_IDS).optional().catch(undefined),
	selectedLoanOffer: z
		.custom<EstoProductType>(
			(value) =>
				!value ||
				[
					...Object.values(ConsumerLoanProduct),
					NonLoanProduct.CREDIT_LINE,
					// @ts-ignore
				].includes(value),
		)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/_main/dashboard')({
	validateSearch: dashboardSearchSchema,
});
