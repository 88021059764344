/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UpdateUserMutationVariables = Types.Exact<{
  user_id: Types.Scalars['Int']['input'];
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  phone?: Types.InputMaybe<Types.Scalars['String']['input']>;
  newsletter_agreement?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type UpdateUserMutation = { update_user?: { id: number } | null };



export const UpdateUserDocument = `
    mutation UpdateUser($user_id: Int!, $email: String, $phone: String, $newsletter_agreement: Boolean) {
  update_user(
    user_id: $user_id
    email: $email
    phone: $phone
    newsletter_agreement: $newsletter_agreement
  ) {
    id
  }
}
    `;

export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUser'],
    mutationFn: (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    ...options
  }
    )};


useUpdateUserMutation.fetcher = (variables: UpdateUserMutationVariables, options?: RequestInit['headers']) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, options);
