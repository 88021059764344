import { cn } from '@/shared/utils/tailwind';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Typography } from '@components/typography';
import { LOCIZE_LOADING_SCREEN_KEYS, LOCIZE_NAMESPACES } from '@config/locize';
import { useTranslation } from 'react-i18next';

export const LoadingScreen = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.loadingScreen);

	return (
		<div className="flex h-full w-full flex-col items-center gap-9 pt-32">
			<LoadingSpinner />
			<div className="relative flex h-11 w-full flex-col items-center overflow-hidden px-6">
				<Typography
					className={cn('absolute inset-0 animate-slide-up1 text-center')}
					variant="m"
				>
					{t(LOCIZE_LOADING_SCREEN_KEYS.text1)}
				</Typography>

				<Typography
					className={cn('absolute inset-0 animate-slide-up2 text-center')}
					style={{ transform: 'translateY(100%)' }}
					variant="m"
				>
					{t(LOCIZE_LOADING_SCREEN_KEYS.text2)}
				</Typography>
			</div>
		</div>
	);
};
