import {
	type CreditAccountWithdrawalFormType,
	InstantPaymentStatus,
} from '@features/credit-account/withdrawal-form';
import { creditAccountWithdrawalApi } from '@pages/credit-account-withdrawal/form/api';
import { getRouteApi } from '@tanstack/react-router';
import { lazy, useCallback, useMemo, useState } from 'react';
import {
	useCalculateNewMonthlyPayment,
	useWithdrawFromCreditAccount,
} from '../hooks';

const CreditAccountWithdrawalForm = lazy(() =>
	import('@features/credit-account/withdrawal-form').then((m) => ({
		default: m.CreditAccountWithdrawalForm,
	})),
);
const CreditAccountWithdrawalFormConfirmView = lazy(() =>
	import('./CreditAccountWithdrawalFormConfirmView').then((m) => ({
		default: m.CreditAccountWithdrawalFormConfirmView,
	})),
);

const CreditAccountWithdrawalFormPendingView = lazy(() =>
	import('./CreditAccountWithdrawalFormPendingView').then((m) => ({
		default: m.CreditAccountWithdrawalFormPendingView,
	})),
);

const routeApi = getRouteApi('/_protected/credit-account-withdrawal/form');

export const CreditAccountWithdrawalFormPage = () => {
	const {
		maxWithdrawalAmount,
		creditAccountId,
		minInstantWithdrawalAmount,
		minWithdrawalAmount,
		annualPctRate,
		maxPeriodMonths,
		unpaidPrincipal,
		instantPaymentFee,
		disposableIncomeWithoutCa,
	} = routeApi.useLoaderData();
	const { fromPathname } = routeApi.useSearch();
	const { data } =
		creditAccountWithdrawalApi.useSuspenseCreditAccountWithdrawalSettingsQuery({
			creditAccountId,
			amount: minInstantWithdrawalAmount,
		});

	const instantPaymentStatus = useMemo(() => {
		const { instant_payment_enabled, instant_payment_fee } =
			data?.credit_account_withdrawal ?? {};

		if (
			!instant_payment_enabled ||
			!instant_payment_fee ||
			instant_payment_fee < 0
		) {
			return InstantPaymentStatus.DISABLED;
		}

		if (instant_payment_fee === 0) {
			return InstantPaymentStatus.ENABLED_BY_DEFAULT;
		}

		return InstantPaymentStatus.ENABLED;
	}, [data?.credit_account_withdrawal]);

	const [withdrawData, setWithdrawData] =
		useState<Nullable<CreditAccountWithdrawalFormType>>(null);
	const [isConfirming, setIsConfirming] = useState(false);

	const { handleWithdrawFromCredit, isWithdrawing, isWithdrawRequested } =
		useWithdrawFromCreditAccount({
			disposableIncomeWithoutCa,
		});

	const handleFormSubmit = useCallback(
		({ amount, isInstantPayment }: CreditAccountWithdrawalFormType) => {
			setWithdrawData({ amount, isInstantPayment });
			setIsConfirming(true);
		},
		[],
	);

	const handleWithdrawConfirm = useCallback(() => {
		if (!withdrawData) {
			throw new Error('Withdraw data is missing');
		}

		handleWithdrawFromCredit({
			amount: withdrawData.amount,
			isInstantPayment: withdrawData.isInstantPayment,
			fromPathname,
			creditAccountId,
		});
	}, [creditAccountId, fromPathname, handleWithdrawFromCredit, withdrawData]);

	const calculateNewMonthlyPayment = useCalculateNewMonthlyPayment({
		annualPctRate,
		maxPeriodMonths,
		unpaidPrincipal,
	});

	if (isWithdrawing || isWithdrawRequested) {
		return (
			<CreditAccountWithdrawalFormPendingView
				isWithdrawRequested={isWithdrawRequested}
			/>
		);
	}

	if (isConfirming && withdrawData) {
		return (
			<CreditAccountWithdrawalFormConfirmView
				amount={withdrawData.amount}
				onSubmit={handleWithdrawConfirm}
				onClose={() => setIsConfirming(false)}
			/>
		);
	}

	return (
		<CreditAccountWithdrawalForm
			calculateNewMonthlyPaymentFn={calculateNewMonthlyPayment}
			instantPaymentFee={instantPaymentFee}
			maxWithdrawalAmount={+maxWithdrawalAmount.toFixed(0)}
			minInstantWithdrawalAmount={minInstantWithdrawalAmount}
			minWithdrawalAmount={minWithdrawalAmount}
			onSubmit={handleFormSubmit}
			defaultValues={withdrawData}
			instantPaymentStatus={instantPaymentStatus}
		/>
	);
};
