export enum DealId {
	PHEROMONE = 'pheromone',
	IRRIGATOR = 'irrigator',
	SINISE_VALGUSE = 'siniseValguse',
	SPORTLIK = 'sportlik',
	CASEPRO = 'casepro',
	GOFIT = 'goFit',
	MOKYKLINE = 'mokykline',
}

export type Deal = {
	id: DealId;
	brand: string;
	title: string;
	discountCode: string;
	discountPercent: number;
	imageSrc: string;
	websiteUrl: string;
};
