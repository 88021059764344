/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type WithdrawFromCreditAccountMutationVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
  amount: Types.Scalars['Float']['input'];
  isInstantPayment: Types.Scalars['Boolean']['input'];
}>;


export type WithdrawFromCreditAccountMutation = { withdraw_from_credit_account?: { eligibility_state: number, hash: string, simple_eligibility_status: string } | null };



export const WithdrawFromCreditAccountDocument = `
    mutation WithdrawFromCreditAccount($creditAccountId: Int!, $amount: Float!, $isInstantPayment: Boolean!) {
  withdraw_from_credit_account(
    credit_account_id: $creditAccountId
    amount: $amount
    is_instant_payment: $isInstantPayment
  ) {
    eligibility_state
    hash
    simple_eligibility_status
  }
}
    `;

export const useWithdrawFromCreditAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<WithdrawFromCreditAccountMutation, TError, WithdrawFromCreditAccountMutationVariables, TContext>) => {
    
    return useMutation<WithdrawFromCreditAccountMutation, TError, WithdrawFromCreditAccountMutationVariables, TContext>(
      {
    mutationKey: ['WithdrawFromCreditAccount'],
    mutationFn: (variables?: WithdrawFromCreditAccountMutationVariables) => fetcher<WithdrawFromCreditAccountMutation, WithdrawFromCreditAccountMutationVariables>(WithdrawFromCreditAccountDocument, variables)(),
    ...options
  }
    )};


useWithdrawFromCreditAccountMutation.fetcher = (variables: WithdrawFromCreditAccountMutationVariables, options?: RequestInit['headers']) => fetcher<WithdrawFromCreditAccountMutation, WithdrawFromCreditAccountMutationVariables>(WithdrawFromCreditAccountDocument, variables, options);
