import { OLD_APP_DOMAIN, PURCHASE_FLOW_DOMAIN } from './envs';

export enum RouteName {
	AUTH = '/auth',
	AUTH_ADDITIONAL_INFO = '/auth-additional-info',
	DASHBOARD = '/dashboard',
	PREMIUM = '/premium',
	PREMIUM_SUBSCRIBE = '/premium/subscribe',
	PREMIUM_UNSUBSCRIBE = '/premium/unsubscribe',
	CREDIT_ACCOUNT_WITHDRAWAL = '/credit-account-withdrawal',
	CREDIT_ACCOUNT_WITHDRAWAL_FORM = '/credit-account-withdrawal/form',
	CREDIT_ACCOUNT_WITHDRAWAL_SUCCESS = '/credit-account-withdrawal/success',
	CREDIT_ACCOUNT_WITHDRAWAL_REJECT = '/credit-account-withdrawal/reject',
	SUBSCRIBE_NEWSLETTER = '/subscribe-newsletter',
}

export const OLD_APP_ROUTE_NAME = {
	home: `${OLD_APP_DOMAIN}`,
	pay: `${OLD_APP_DOMAIN}/pay`,
	payPremium: `${OLD_APP_DOMAIN}/pay/invoice?opt-out-onboard-to-premium-account-overdue=1`,
	invoices: `${OLD_APP_DOMAIN}/invoices`,
	profile: `${OLD_APP_DOMAIN}/profile`,
	premium: `${OLD_APP_DOMAIN}?showPremium=1`,
	contracts: `${OLD_APP_DOMAIN}/contracts`,
	applicationContract: `${OLD_APP_DOMAIN}/contracts/:applicationReferenceKey`,
	creditAccountContract: `${OLD_APP_DOMAIN}#getCredit`,
	applicationReduceMonthlyPayment: `${OLD_APP_DOMAIN}/reduce-monthly-payment/:applicationReferenceKey`,
	creditAccountLimitIncrease: `${OLD_APP_DOMAIN}/ca-limit-increase`,
	creditAccountModification: `${OLD_APP_DOMAIN}/ca-modification`,
} as const;

export const PURCHASE_FLOW_ROUTE_NAME = {
	creditLine: `${PURCHASE_FLOW_DOMAIN}/credit-line`,
	creditLineWithdrawal: `${PURCHASE_FLOW_DOMAIN}/credit-line-withdrawal?hash=$hash`,
} as const;
