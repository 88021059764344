import { LOCIZE_DEALS_KEYS } from '@config/locize';
import { type Deal, DealId } from './types';

export const DEAL: Record<DealId, Deal> = {
	[DealId.PHEROMONE]: {
		id: DealId.PHEROMONE,
		brand: 'Pheromone',
		title: LOCIZE_DEALS_KEYS.pheromone,
		discountCode: 'ESTO40',
		discountPercent: 40,
		imageSrc: '/images/dashboard/deals/pheromone.webp',
		websiteUrl:
			'https://pheromone.ee/?utm_source=esto&utm_medium=shop&utm_campaign=shop',
	},
	[DealId.IRRIGATOR]: {
		id: DealId.IRRIGATOR,
		brand: 'Irrigaator',
		title: LOCIZE_DEALS_KEYS.irrigaator,
		discountCode: 'ESTO15',
		discountPercent: 15,
		imageSrc: '/images/dashboard/deals/irrigaator.webp',
		websiteUrl: 'https://www.irrigaator.ee/ostukorv',
	},
	[DealId.SINISE_VALGUSE]: {
		id: DealId.SINISE_VALGUSE,
		brand: 'Sinise Valguse',
		title: LOCIZE_DEALS_KEYS.siniseValguse,
		discountCode: 'ESTO',
		discountPercent: 10,
		imageSrc: '/images/dashboard/deals/sinisevalguseprillid.webp',
		websiteUrl: 'https://sinisevalguseprillid.ee',
	},
	[DealId.SPORTLIK]: {
		id: DealId.SPORTLIK,
		brand: 'Sportlik',
		title: LOCIZE_DEALS_KEYS.sportlik,
		discountCode: 'ESTO10',
		discountPercent: 10,
		imageSrc: '/images/dashboard/deals/sportlik.webp',
		websiteUrl: 'https://sportlik.ee',
	},
	[DealId.CASEPRO]: {
		id: DealId.CASEPRO,
		brand: 'Casepro',
		title: LOCIZE_DEALS_KEYS.casepro,
		discountCode: 'ESTO20',
		discountPercent: 20,
		imageSrc: '/images/dashboard/deals/casepro.webp',
		websiteUrl: 'https://casepro.ee',
	},
	[DealId.GOFIT]: {
		id: DealId.GOFIT,
		brand: 'GoFit',
		title: LOCIZE_DEALS_KEYS.goFit,
		discountCode: 'ESTO20',
		discountPercent: 20,
		imageSrc: '/images/dashboard/deals/go-fit.webp',
		websiteUrl: 'https://gofit.ee',
	},
	[DealId.MOKYKLINE]: {
		id: DealId.MOKYKLINE,
		brand: 'Mokyklines',
		title: LOCIZE_DEALS_KEYS.mokykline,
		discountCode: 'ESTO10',
		discountPercent: 10,
		imageSrc: '/images/dashboard/deals/mokyklines.webp',
		websiteUrl: 'https://www.mokyklinesuniformos.lt',
	},
};
