import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/shared/utils/tailwind';

export const buttonVariants = cva(
	'rounded-[6.25rem] inline-flex justify-center items-center shrink-0 gap-2 ring-offset-background transition-colors focus-visible:outline-none whitespace-nowrap disabled:cursor-not-allowed text-balance text-center',
	{
		variants: {
			size: {
				regular:
					'h-12 px-6 font-semibold text-[1rem] tracking-[-.02rem] leading-normal',
				small: 'h-8 px-4 text-[0.875rem] leading-6 tracking-[-.00875rem]',
				large:
					'h-14 px-7 font-black uppercase text-[1.125rem] tracking-[-.045rem] leading-normal',
			},
			variant: {
				black:
					'bg-primary-black text-primary-white md:hover:bg-primary-brand02 disabled:data-[is-loading="false"]:bg-neutral-400 disabled:data-[is-loading="false"]:text-neutral-300',
				white:
					'bg-primary-white text-primary-black md:hover:bg-neutral-100 disabled:data-[is-loading="false"]:text-neutral-300',
				grey: 'bg-neutral-100 text-primary-black md:hover:bg-primary-black md:hover:text-primary-white disabled:data-[is-loading="false"]:bg-neutral-400 disabled:data-[is-loading="false"]:text-neutral-300',
				blue: 'bg-primary-brand02 text-primary-white md:hover:bg-primary-black disabled:data-[is-loading="false"]:bg-neutral-400 disabled:data-[is-loading="false"]:text-neutral-300',
				outline:
					'bg-primary-white text-primary-black md:hover:bg-primary-black md:hover:text-primary-white border-2 border-primary-black disabled:data-[is-loading="false"]:text-neutral-300 disabled:data-[is-loading="false"]:border-neutral-400',
			},
		},
		defaultVariants: {
			size: 'regular',
			variant: 'black',
		},
	},
);

type ButtonCvaType = VariantProps<typeof buttonVariants>;

export type ButtonProps = {
	asChild?: boolean;
	fullWidth?: boolean;
	isLoading?: boolean;
	testId?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	ButtonCvaType;

export type ButtonVariant = NonNullable<ButtonProps['variant']>;
export type ButtonSize = NonNullable<ButtonProps['size']>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			size = 'regular',
			asChild = false,
			variant = 'black',
			children,
			fullWidth,
			isLoading = false,
			disabled,
			testId,
			...props
		},
		ref,
	) => {
		const Comp = asChild ? Slot : 'button';

		return (
			<Comp
				{...props}
				ref={ref}
				className={cn(
					buttonVariants({
						variant,
						size,
						className,
					}),
					fullWidth && 'w-full',
					isLoading && 'disabled:cursor-wait',
				)}
				data-is-loading={isLoading}
				data-testid={testId}
				disabled={isLoading || disabled}
			>
				{isLoading ? <ButtonLoader size={size} variant={variant} /> : children}
			</Comp>
		);
	},
);
Button.displayName = 'Button';

const buttonLoaderDotVariants = cva<{
	variant: Record<ButtonVariant, string>;
	size: Record<ButtonSize, string>;
}>('animate-loader rounded-full', {
	variants: {
		variant: {
			black: 'bg-primary-white',
			blue: 'bg-primary-white',
			white: 'bg-primary-black',
			grey: 'bg-primary-black',
			outline: 'bg-primary-black',
		},
		size: {
			regular: 'size-2.5',
			small: 'size-2',
			large: 'size-3.5',
		},
	},
});

const buttonLoaderContainerVariants = cva<{
	size: Record<ButtonSize, string>;
}>('flex items-center justify-center', {
	variants: {
		size: {
			regular: 'h-4 gap-[0.1875rem]',
			small: 'h-3 gap-[0.125rem]',
			large: 'h-6 gap-[0.3125rem]',
		},
	},
});

const ButtonLoader = ({
	variant,
	size,
}: VariantProps<typeof buttonVariants>) => {
	const dotClassName = buttonLoaderDotVariants({ variant, size });
	const containerClassName = buttonLoaderContainerVariants({ size });

	return (
		<div className={containerClassName}>
			<div className={dotClassName} />
			<div className={cn('animation-delay-200', dotClassName)} />
			<div className={cn('animation-delay-400', dotClassName)} />
		</div>
	);
};

export { Button };
