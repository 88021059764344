import { AuthMethod, EparakstsAuthorizationMethod } from './api.gen';
import type { EstoProductType } from './product';

export enum SupportedCountries {
	ET = 'EE',
	LV = 'LV',
	LT = 'LT',
}

export enum AppLanguage {
	EN = 'en',
	ET = 'et',
	LV = 'lv',
	LT = 'lt',
	RU = 'ru',
}

export type LegalInfo = {
	readonly infoEmail: string;
	readonly phone: {
		readonly number: string;
		readonly label: string;
	};
	readonly address: string;
	readonly name: string;
};

export enum CountryPhoneCode {
	EE = '+372',
	LV = '+371',
	LT = '+370',
}

type CountryConfig = {
	readonly authMethods: Array<AppAuthMethod>;
	readonly starProduct: EstoProductType;
	readonly supportUrlByLanguage: Record<AppLanguage, string>;
	readonly legalInfo: LegalInfo;
	readonly termsUrlByLanguage: Record<AppLanguage, string>;
	readonly phoneCode: CountryPhoneCode;
	readonly isRejectedCAWRedirectionToCamEnabled: boolean;
};

export type AppConfigByCountry = Readonly<
	Record<SupportedCountries, CountryConfig>
>;

export type AppConfig = { name: string } & CountryConfig;

export enum AppAuthMethod {
	ID_CARD = AuthMethod.ID_CARD,
	MOBILE = AuthMethod.MOBILE,
	PASSWORD = AuthMethod.PASSWORD,
	PAYSERA_BANKLINK = AuthMethod.PAYSERA_BANKLINK,
	SMART_ID = AuthMethod.SMART_ID,
	EPARAKSTS_MOBILE = `${AuthMethod.EPARAKSTS}_${EparakstsAuthorizationMethod.MOBILE}`,
	EPARAKSTS_SMARTCARD = `${AuthMethod.EPARAKSTS}_${EparakstsAuthorizationMethod.SMARTCARD}`,
}

export enum OldApplicationRedirectStatus {
	INACTIVE = '0',
	ACTIVE = '1',
	SPECIFIC_USERS = '2',
}
