import type * as SliderPrimitive from '@radix-ui/react-slider';
import { forwardRef } from 'react';
import { Slider, type SliderProps } from './ui/slider';

type SliderWithLabelProps = {
	className?: string;
} & SliderProps;

export const SliderWithLabel = forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	SliderWithLabelProps
>((sliderProps, ref) => {
	return <Slider ref={ref} {...sliderProps} />;
});
