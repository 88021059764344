import { SimpleEligibilityStatus } from '@/shared/types';
import { CreditAccountWithdrawalEligibilityState } from '@/shared/types/credit-account-withdrawal';
import { MIN_USER_DISPOSABLE_INCOME } from '@config/credit-account-withdrawal';
import { OLD_APP_ROUTE_NAME, PURCHASE_FLOW_ROUTE_NAME } from '@config/routes';
import { useAppConfig } from '@hooks/system/useAppConfig';
import { creditAccountWithdrawalApi } from '@pages/credit-account-withdrawal/form/api';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useState } from 'react';

type UseWithdrawFromCreditAccountParams = {
	disposableIncomeWithoutCa: number;
};

export const useWithdrawFromCreditAccount = ({
	disposableIncomeWithoutCa,
}: UseWithdrawFromCreditAccountParams) => {
	const { isRejectedCAWRedirectionToCamEnabled } = useAppConfig();
	const navigate = useNavigate();

	const [isWithdrawing, setIsWithdrawing] = useState(false);
	const [isWithdrawRequested, setIsWithdrawRequested] = useState(false);

	const { mutateAsync: withdrawFromCreditAccountMutation } =
		creditAccountWithdrawalApi.useWithdrawFromCreditAccountMutation();

	const handleWithdrawFromCredit = useCallback(
		async ({
			amount,
			isInstantPayment,
			fromPathname,
			creditAccountId,
		}: {
			amount: number;
			isInstantPayment: boolean;
			fromPathname?: string;
			creditAccountId: number;
		}) => {
			setIsWithdrawing(true);
			try {
				const { withdraw_from_credit_account } =
					await withdrawFromCreditAccountMutation({
						amount,
						isInstantPayment,
						creditAccountId,
					});

				if (!withdraw_from_credit_account) {
					throw new Error('Withdrawal failed');
				}

				const { eligibility_state, hash, simple_eligibility_status } =
					withdraw_from_credit_account;
				setIsWithdrawRequested(true);

				if (
					eligibility_state === CreditAccountWithdrawalEligibilityState.NEGATIVE
				) {
					if (
						isRejectedCAWRedirectionToCamEnabled &&
						simple_eligibility_status ===
							SimpleEligibilityStatus.DSTI_DSCR_INSUFFICIENT &&
						disposableIncomeWithoutCa > MIN_USER_DISPOSABLE_INCOME
					) {
						setTimeout(() => {
							window.open(
								OLD_APP_ROUTE_NAME.creditAccountModification,
								'_self',
							);
						}, 2000);
						return;
					}

					setTimeout(() => {
						navigate({
							to: '/credit-account-withdrawal/reject',
							search: {
								fromPathname,
								hash,
							},
							replace: true,
						});
					}, 2000);

					setIsWithdrawing(false);

					return;
				}

				if (
					eligibility_state === CreditAccountWithdrawalEligibilityState.SCORING
				) {
					setTimeout(() => {
						window.open(
							PURCHASE_FLOW_ROUTE_NAME.creditLineWithdrawal.replace(
								'$hash',
								hash,
							),
							'_self',
						);
					}, 2000);

					return;
				}

				setTimeout(() => {
					navigate({
						to: '/credit-account-withdrawal/success',
						search: {
							fromPathname,
							hash,
						},
						replace: true,
					});
				}, 2000);

				setIsWithdrawing(false);
			} catch {
				navigate({
					replace: true,
					search: { error: true },
				});
			}
		},
		[
			navigate,
			withdrawFromCreditAccountMutation,
			isRejectedCAWRedirectionToCamEnabled,
			disposableIncomeWithoutCa,
		],
	);

	return {
		handleWithdrawFromCredit,
		isWithdrawing,
		isWithdrawRequested,
	};
};
