import SliderHandleIcon from '@icons/slider-handle.svg?react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';

import { cn } from '@/shared/utils/tailwind';

type SliderProps = React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>;

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	SliderProps
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={cn(
			'relative flex w-full touch-none select-none items-center py-2.5',
			className,
		)}
		{...props}
	>
		<SliderPrimitive.Track className="relative h-5 w-full grow overflow-hidden rounded-full border-4 border-neutral-100 bg-neutral-100">
			<SliderPrimitive.Range className="absolute h-full bg-system-green" />
		</SliderPrimitive.Track>
		<SliderPrimitive.Thumb className="flex cursor-grab justify-center focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0">
			<SliderHandleIcon />
		</SliderPrimitive.Thumb>
	</SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider, type SliderProps };
