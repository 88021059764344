/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type PayseraLoginQueryVariables = Types.Exact<{
  session_id: Types.Scalars['String']['input'];
}>;


export type PayseraLoginQuery = { status?: Types.LoginPollStatus | null };



export const PayseraLoginDocument = `
    query PayseraLogin($session_id: String!) {
  status: paysera_login(session_id: $session_id)
}
    `;

export const usePayseraLoginQuery = <
      TData = PayseraLoginQuery,
      TError = unknown
    >(
      variables: PayseraLoginQueryVariables,
      options?: Omit<UseQueryOptions<PayseraLoginQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PayseraLoginQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PayseraLoginQuery, TError, TData>(
      {
    queryKey: ['PayseraLogin', variables],
    queryFn: fetcher<PayseraLoginQuery, PayseraLoginQueryVariables>(PayseraLoginDocument, variables),
    ...options,
  }
    )};

usePayseraLoginQuery.getKey = (variables: PayseraLoginQueryVariables) => ['PayseraLogin', variables];

export const useSuspensePayseraLoginQuery = <
      TData = PayseraLoginQuery,
      TError = unknown
    >(
      variables: PayseraLoginQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<PayseraLoginQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<PayseraLoginQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<PayseraLoginQuery, TError, TData>(
      {
    queryKey: ['PayseraLoginSuspense', variables],
    queryFn: fetcher<PayseraLoginQuery, PayseraLoginQueryVariables>(PayseraLoginDocument, variables),
    ...options
  }
    )};

useSuspensePayseraLoginQuery.getKey = (variables: PayseraLoginQueryVariables) => ['PayseraLoginSuspense', variables];


usePayseraLoginQuery.fetcher = (variables: PayseraLoginQueryVariables, options?: RequestInit['headers']) => fetcher<PayseraLoginQuery, PayseraLoginQueryVariables>(PayseraLoginDocument, variables, options);
