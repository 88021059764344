import { LoginPollStatus } from '@/shared/types';
import { APP_CONFIG } from '@config/app';
import { getUserFx } from '@entities/user';
import { EPARAKSTS_LOGIN_CODE_SEARCH_NAME } from '@features/auth/by-eparaksts';
import {
	PAYSERA_LOGIN_STATUS_SEARCH_NAME,
	PayseraLoginStatus,
} from '@features/auth/by-paysera-banklink/login';
import { LOCAL_STORAGE_SESSION_ID_KEY } from '@hooks/auth/useLocalStorageSessionId';
import { serverFetch } from '@lib/serverFetcher';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { usePayseraLoginQuery } from '@widgets/auth/paysera-banklink-form/api';
import { toast } from 'sonner';
import * as z from 'zod';

const authSearchSchema = z.object({
	authMethod: z.enum(APP_CONFIG.authMethods).catch(APP_CONFIG.authMethods[0]),
	invite: z.string().optional().catch(undefined),
	token: z.string().optional().catch(undefined),
	[PAYSERA_LOGIN_STATUS_SEARCH_NAME]: z
		.nativeEnum(PayseraLoginStatus)
		.optional()
		.catch(undefined),
	redirectUrl: z.string().optional().catch(undefined),
	redirect_back: z.string().optional().catch(undefined),
	// eparaksts auth code
	[EPARAKSTS_LOGIN_CODE_SEARCH_NAME]: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_unprotected/auth')({
	loaderDeps: ({ search: { payseraLoginStatus, redirectUrl } }) => ({
		payseraLoginStatus,
		redirectUrl,
	}),
	loader: async ({ deps: { payseraLoginStatus, redirectUrl } }) => {
		const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY);

		if (payseraLoginStatus === PayseraLoginStatus.SUCCESS && !!sessionId) {
			const data = await serverFetch(usePayseraLoginQuery, {
				variables: {
					session_id: sessionId,
				},
			});

			if (data?.status === LoginPollStatus.SUCCESSFUL) {
				const user = await getUserFx();

				if (!user) {
					toast('Something went wrong. Please try again');
					return;
				}

				throw redirect({
					to: redirectUrl ?? '/auth-additional-info',
					replace: true,
				});
			}
		}
	},
	validateSearch: authSearchSchema,
});
