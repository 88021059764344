import { isProd } from '@/shared/config/envs';
import { routeChangedEv } from '@entities/app';
import { NotFoundPage } from '@pages/404';
import { ErrorPage } from '@pages/error';
import {
	Outlet,
	ScrollRestoration,
	createRootRoute,
} from '@tanstack/react-router';
import { Suspense, lazy } from 'react';

const TanStackRouterDevtools = isProd
	? () => null
	: lazy(() =>
			import('@tanstack/router-devtools').then((res) => ({
				default: res.TanStackRouterDevtools,
			})),
		);
export const Route = createRootRoute({
	loader: () => {
		routeChangedEv();
	},
	component: () => (
		<>
			<ScrollRestoration />
			<Outlet />
			<Suspense>
				<TanStackRouterDevtools />
			</Suspense>
		</>
	),
	errorComponent: ErrorPage,
	notFoundComponent: NotFoundPage,
});
