import { cn } from '@/shared/utils/tailwind';
import * as React from 'react';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	isError?: boolean;
	dataTestId?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, isError, dataTestId, ...props }, ref) => {
		return (
			<input
				ref={ref}
				className={cn(
					'autofill:!bg-neutral-50 flex h-12 w-full rounded-[.875rem] border border-transparent bg-neutral-50 px-3.5 py-3 text-base text-primary-black ring-offset-background disabled:cursor-not-allowed file:border-0 focus-visible:border-primary-black file:bg-transparent file:font-normal file:text-sm placeholder:text-neutral-400 focus-visible:outline-none',
					className,
					isError && 'border-system-red focus-visible:border-system-red',
				)}
				data-testid={dataTestId}
				type={type}
				{...props}
			/>
		);
	},
);
Input.displayName = 'Input';

export { Input };
