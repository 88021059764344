import { router } from '@/app/router/AppRouter';
import { APP_COUNTRY, SENTRY_DSN } from '@config/envs';
import * as Sentry from '@sentry/react';

if (SENTRY_DSN) {
	Sentry.init({
		dsn: SENTRY_DSN,
		environment: APP_COUNTRY,
		integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
		ignoreErrors: [
			'TypeError: Failed to fetch',
			'TypeError: NetworkError when attempting to fetch resource.',
		],
		// Performance Monitoring
		tracesSampleRate: 0.1, //  Capture 100% of the transactions
	});
}
