import { AppLanguage } from '@/shared/types';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const GLOBAL_LOCAL_STORAGE_KEYS = {
	browsingSessionId: 'browsing_session_id',
} as const;

export const COUNTRY_FLAG_BY_LOCALE = {
	[AppLanguage.EN]: '/images/locale-flags/en.svg',
	[AppLanguage.ET]: '/images/locale-flags/et.svg',
	[AppLanguage.LV]: '/images/locale-flags/lv.svg',
	[AppLanguage.LT]: '/images/locale-flags/lt.svg',
	[AppLanguage.RU]: '/images/locale-flags/ru.svg',
} as const satisfies Record<AppLanguage, string>;
