/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserAuthInfoFragment = { id: number, email?: string | null, phone?: string | null, newsletter_agreement: boolean, disposable_income_without_ca?: number | null, language_abbr: string, profile?: { user_id: number, first_name?: string | null, last_name?: string | null } | null };

export type AuthCheckQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthCheckQuery = { me?: { id: number, email?: string | null, phone?: string | null, newsletter_agreement: boolean, disposable_income_without_ca?: number | null, language_abbr: string, profile?: { user_id: number, first_name?: string | null, last_name?: string | null } | null } | null };

export type UserCreditAccountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCreditAccountQuery = { me?: { id: number, credit_accounts?: Array<{ id: number, credit_limit: number, status?: Types.CreditAccountStatus | null, unpaid_principal: number, can_request_limit_increase: boolean, signed_at?: string | null } | null> | null } | null };

export type UserCreditAccountFirstWithdrawalQueryVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
}>;


export type UserCreditAccountFirstWithdrawalQuery = { credit_account_withdrawals?: { data?: Array<{ id: number } | null> | null } | null };

export type UserPremiumSubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPremiumSubscriptionQuery = { me?: { id: number, premium_subscription_status: string, active_premium_subscription?: { id: number, fee_amount: number, end_at: string } | null } | null };


export const UserAuthInfoFragmentDoc = `
    fragment UserAuthInfo on User {
  id
  email
  phone
  newsletter_agreement
  disposable_income_without_ca
  profile {
    user_id
    first_name
    last_name
  }
  language_abbr
}
    `;
export const AuthCheckDocument = `
    query AuthCheck {
  me(is_me: true) {
    ...UserAuthInfo
  }
}
    ${UserAuthInfoFragmentDoc}`;

export const useAuthCheckQuery = <
      TData = AuthCheckQuery,
      TError = unknown
    >(
      variables?: AuthCheckQueryVariables,
      options?: Omit<UseQueryOptions<AuthCheckQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<AuthCheckQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<AuthCheckQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['AuthCheck'] : ['AuthCheck', variables],
    queryFn: fetcher<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, variables),
    ...options
  }
    )};

useAuthCheckQuery.getKey = (variables?: AuthCheckQueryVariables) => variables === undefined ? ['AuthCheck'] : ['AuthCheck', variables];

export const useSuspenseAuthCheckQuery = <
      TData = AuthCheckQuery,
      TError = unknown
    >(
      variables?: AuthCheckQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<AuthCheckQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<AuthCheckQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<AuthCheckQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['AuthCheckSuspense'] : ['AuthCheckSuspense', variables],
    queryFn: fetcher<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, variables),
    ...options
  }
    )};

useSuspenseAuthCheckQuery.getKey = (variables?: AuthCheckQueryVariables) => variables === undefined ? ['AuthCheckSuspense'] : ['AuthCheckSuspense', variables];


useAuthCheckQuery.fetcher = (variables?: AuthCheckQueryVariables, options?: RequestInit['headers']) => fetcher<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, variables, options);

export const UserCreditAccountDocument = `
    query UserCreditAccount {
  me(is_me: true) {
    id
    credit_accounts {
      id
      credit_limit
      status
      unpaid_principal
      can_request_limit_increase
      signed_at
    }
  }
}
    `;

export const useUserCreditAccountQuery = <
      TData = UserCreditAccountQuery,
      TError = unknown
    >(
      variables?: UserCreditAccountQueryVariables,
      options?: Omit<UseQueryOptions<UserCreditAccountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserCreditAccountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserCreditAccountQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditAccount'] : ['UserCreditAccount', variables],
    queryFn: fetcher<UserCreditAccountQuery, UserCreditAccountQueryVariables>(UserCreditAccountDocument, variables),
    ...options
  }
    )};

useUserCreditAccountQuery.getKey = (variables?: UserCreditAccountQueryVariables) => variables === undefined ? ['UserCreditAccount'] : ['UserCreditAccount', variables];

export const useSuspenseUserCreditAccountQuery = <
      TData = UserCreditAccountQuery,
      TError = unknown
    >(
      variables?: UserCreditAccountQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserCreditAccountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserCreditAccountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserCreditAccountQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditAccountSuspense'] : ['UserCreditAccountSuspense', variables],
    queryFn: fetcher<UserCreditAccountQuery, UserCreditAccountQueryVariables>(UserCreditAccountDocument, variables),
    ...options
  }
    )};

useSuspenseUserCreditAccountQuery.getKey = (variables?: UserCreditAccountQueryVariables) => variables === undefined ? ['UserCreditAccountSuspense'] : ['UserCreditAccountSuspense', variables];


useUserCreditAccountQuery.fetcher = (variables?: UserCreditAccountQueryVariables, options?: RequestInit['headers']) => fetcher<UserCreditAccountQuery, UserCreditAccountQueryVariables>(UserCreditAccountDocument, variables, options);

export const UserCreditAccountFirstWithdrawalDocument = `
    query UserCreditAccountFirstWithdrawal($creditAccountId: Int!) {
  credit_account_withdrawals(limit: 1, credit_account_id: $creditAccountId) {
    data {
      id
    }
  }
}
    `;

export const useUserCreditAccountFirstWithdrawalQuery = <
      TData = UserCreditAccountFirstWithdrawalQuery,
      TError = unknown
    >(
      variables: UserCreditAccountFirstWithdrawalQueryVariables,
      options?: Omit<UseQueryOptions<UserCreditAccountFirstWithdrawalQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserCreditAccountFirstWithdrawalQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserCreditAccountFirstWithdrawalQuery, TError, TData>(
      {
    queryKey: ['UserCreditAccountFirstWithdrawal', variables],
    queryFn: fetcher<UserCreditAccountFirstWithdrawalQuery, UserCreditAccountFirstWithdrawalQueryVariables>(UserCreditAccountFirstWithdrawalDocument, variables),
    ...options
  }
    )};

useUserCreditAccountFirstWithdrawalQuery.getKey = (variables: UserCreditAccountFirstWithdrawalQueryVariables) => ['UserCreditAccountFirstWithdrawal', variables];

export const useSuspenseUserCreditAccountFirstWithdrawalQuery = <
      TData = UserCreditAccountFirstWithdrawalQuery,
      TError = unknown
    >(
      variables: UserCreditAccountFirstWithdrawalQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserCreditAccountFirstWithdrawalQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserCreditAccountFirstWithdrawalQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserCreditAccountFirstWithdrawalQuery, TError, TData>(
      {
    queryKey: ['UserCreditAccountFirstWithdrawalSuspense', variables],
    queryFn: fetcher<UserCreditAccountFirstWithdrawalQuery, UserCreditAccountFirstWithdrawalQueryVariables>(UserCreditAccountFirstWithdrawalDocument, variables),
    ...options
  }
    )};

useSuspenseUserCreditAccountFirstWithdrawalQuery.getKey = (variables: UserCreditAccountFirstWithdrawalQueryVariables) => ['UserCreditAccountFirstWithdrawalSuspense', variables];


useUserCreditAccountFirstWithdrawalQuery.fetcher = (variables: UserCreditAccountFirstWithdrawalQueryVariables, options?: RequestInit['headers']) => fetcher<UserCreditAccountFirstWithdrawalQuery, UserCreditAccountFirstWithdrawalQueryVariables>(UserCreditAccountFirstWithdrawalDocument, variables, options);

export const UserPremiumSubscriptionDocument = `
    query UserPremiumSubscription {
  me(is_me: true) {
    id
    premium_subscription_status
    active_premium_subscription {
      id
      fee_amount
      end_at
    }
  }
}
    `;

export const useUserPremiumSubscriptionQuery = <
      TData = UserPremiumSubscriptionQuery,
      TError = unknown
    >(
      variables?: UserPremiumSubscriptionQueryVariables,
      options?: Omit<UseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserPremiumSubscriptionQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserPremiumSubscription'] : ['UserPremiumSubscription', variables],
    queryFn: fetcher<UserPremiumSubscriptionQuery, UserPremiumSubscriptionQueryVariables>(UserPremiumSubscriptionDocument, variables),
    ...options
  }
    )};

useUserPremiumSubscriptionQuery.getKey = (variables?: UserPremiumSubscriptionQueryVariables) => variables === undefined ? ['UserPremiumSubscription'] : ['UserPremiumSubscription', variables];

export const useSuspenseUserPremiumSubscriptionQuery = <
      TData = UserPremiumSubscriptionQuery,
      TError = unknown
    >(
      variables?: UserPremiumSubscriptionQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserPremiumSubscriptionQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserPremiumSubscriptionQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserPremiumSubscriptionSuspense'] : ['UserPremiumSubscriptionSuspense', variables],
    queryFn: fetcher<UserPremiumSubscriptionQuery, UserPremiumSubscriptionQueryVariables>(UserPremiumSubscriptionDocument, variables),
    ...options
  }
    )};

useSuspenseUserPremiumSubscriptionQuery.getKey = (variables?: UserPremiumSubscriptionQueryVariables) => variables === undefined ? ['UserPremiumSubscriptionSuspense'] : ['UserPremiumSubscriptionSuspense', variables];


useUserPremiumSubscriptionQuery.fetcher = (variables?: UserPremiumSubscriptionQueryVariables, options?: RequestInit['headers']) => fetcher<UserPremiumSubscriptionQuery, UserPremiumSubscriptionQueryVariables>(UserPremiumSubscriptionDocument, variables, options);
