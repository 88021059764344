import { CreditAccountStatus } from '@/shared/types';
import { serverFetch } from '@lib/serverFetcher';
import {
	DEFAULT_MIN_INSTANT_PAYMENT_WITHDRAWAL_AMOUNT,
	DEFAULT_MIN_WITHDRAWAL_AMOUNT,
	creditAccountWithdrawalApi,
} from '@pages/credit-account-withdrawal/form';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { toast } from 'sonner';

export const Route = createFileRoute(
	'/_protected/credit-account-withdrawal/form',
)({
	loader: async () => {
		const data = await serverFetch(
			creditAccountWithdrawalApi.useUserCreditAccountWithdrawalFormInfoQuery,
		);

		if (!data?.me) {
			toast('Something went wrong. Please try again');
			throw redirect({
				to: '/dashboard',
				replace: true,
			});
		}
		const { credit_accounts, disposable_income_without_ca } = data.me;

		const activeCreditAccount = credit_accounts?.find(
			(creditAccount) => creditAccount?.status === CreditAccountStatus.ACTIVE,
		);

		if (!activeCreditAccount) {
			throw redirect({
				replace: true,
				to: '/dashboard',
			});
		}

		const { minWithdrawalAmount, minInstantWithdrawalAmount } =
			data?.pricing?.reduce<{
				minWithdrawalAmount: number;
				minInstantWithdrawalAmount: number;
			}>(
				(acc, p) => {
					if (!p) {
						return acc;
					}
					if (p.key === 'credit_acc.min_withdrawal_amount') {
						acc.minWithdrawalAmount = +p.value;
					}

					if (p.key === 'credit_acc.min_instant_withdrawal_amount') {
						acc.minInstantWithdrawalAmount = +p.value;
					}

					return acc;
				},
				{
					minWithdrawalAmount: DEFAULT_MIN_WITHDRAWAL_AMOUNT,
					minInstantWithdrawalAmount:
						DEFAULT_MIN_INSTANT_PAYMENT_WITHDRAWAL_AMOUNT,
				},
			) ?? {
				minWithdrawalAmount: DEFAULT_MIN_WITHDRAWAL_AMOUNT,
				minInstantWithdrawalAmount:
					DEFAULT_MIN_INSTANT_PAYMENT_WITHDRAWAL_AMOUNT,
			};

		const maxWithdrawalAmount =
			activeCreditAccount.credit_limit - activeCreditAccount.unpaid_principal;

		return {
			maxWithdrawalAmount,
			unpaidPrincipal: activeCreditAccount.unpaid_principal,
			annualPctRate: activeCreditAccount.annual_pct_rate,
			maxPeriodMonths: activeCreditAccount.max_period_months,
			creditAccountId: activeCreditAccount.id,
			minWithdrawalAmount,
			minInstantWithdrawalAmount,
			instantPaymentFee: activeCreditAccount.instant_payment_fee,
			disposableIncomeWithoutCa: disposable_income_without_ca ?? 0,
		};
	},
});
