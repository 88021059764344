import { useWithdrawFromCreditAccountMutation } from './mutations.gen';
import {
	useSuspenseCreditAccountWithdrawalSettingsQuery,
	useSuspenseUserCreditAccountWithdrawalFormInfoQuery,
	useUserCreditAccountWithdrawalFormInfoQuery,
} from './queries.gen';

export const creditAccountWithdrawalApi = {
	useSuspenseUserCreditAccountWithdrawalFormInfoQuery,
	useSuspenseCreditAccountWithdrawalSettingsQuery,
	useWithdrawFromCreditAccountMutation,
	useUserCreditAccountWithdrawalFormInfoQuery,
};
