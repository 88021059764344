/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserCreditAccountWithdrawalFormInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserCreditAccountWithdrawalFormInfoQuery = { me?: { id: number, disposable_income_without_ca?: number | null, credit_accounts?: Array<{ id: number, credit_limit: number, status?: Types.CreditAccountStatus | null, unpaid_principal: number, annual_pct_rate: number, max_period_months: number, instant_payment_fee: number } | null> | null } | null, pricing?: Array<{ key: string, value: string } | null> | null };

export type CreditAccountWithdrawalSettingsQueryVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
  amount: Types.Scalars['Float']['input'];
}>;


export type CreditAccountWithdrawalSettingsQuery = { credit_account_withdrawal?: { instant_payment_enabled: boolean, instant_payment_fee: number } | null };



export const UserCreditAccountWithdrawalFormInfoDocument = `
    query UserCreditAccountWithdrawalFormInfo {
  me(is_me: true) {
    id
    disposable_income_without_ca
    credit_accounts {
      id
      credit_limit
      status
      unpaid_principal
      annual_pct_rate
      max_period_months
      instant_payment_fee
    }
  }
  pricing(
    keys: ["credit_acc.min_withdrawal_amount", "credit_acc.min_instant_withdrawal_amount"]
  ) {
    key
    value
  }
}
    `;

export const useUserCreditAccountWithdrawalFormInfoQuery = <
      TData = UserCreditAccountWithdrawalFormInfoQuery,
      TError = unknown
    >(
      variables?: UserCreditAccountWithdrawalFormInfoQueryVariables,
      options?: Omit<UseQueryOptions<UserCreditAccountWithdrawalFormInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserCreditAccountWithdrawalFormInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserCreditAccountWithdrawalFormInfoQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditAccountWithdrawalFormInfo'] : ['UserCreditAccountWithdrawalFormInfo', variables],
    queryFn: fetcher<UserCreditAccountWithdrawalFormInfoQuery, UserCreditAccountWithdrawalFormInfoQueryVariables>(UserCreditAccountWithdrawalFormInfoDocument, variables),
    ...options
  }
    )};

useUserCreditAccountWithdrawalFormInfoQuery.getKey = (variables?: UserCreditAccountWithdrawalFormInfoQueryVariables) => variables === undefined ? ['UserCreditAccountWithdrawalFormInfo'] : ['UserCreditAccountWithdrawalFormInfo', variables];

export const useSuspenseUserCreditAccountWithdrawalFormInfoQuery = <
      TData = UserCreditAccountWithdrawalFormInfoQuery,
      TError = unknown
    >(
      variables?: UserCreditAccountWithdrawalFormInfoQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserCreditAccountWithdrawalFormInfoQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserCreditAccountWithdrawalFormInfoQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserCreditAccountWithdrawalFormInfoQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserCreditAccountWithdrawalFormInfoSuspense'] : ['UserCreditAccountWithdrawalFormInfoSuspense', variables],
    queryFn: fetcher<UserCreditAccountWithdrawalFormInfoQuery, UserCreditAccountWithdrawalFormInfoQueryVariables>(UserCreditAccountWithdrawalFormInfoDocument, variables),
    ...options
  }
    )};

useSuspenseUserCreditAccountWithdrawalFormInfoQuery.getKey = (variables?: UserCreditAccountWithdrawalFormInfoQueryVariables) => variables === undefined ? ['UserCreditAccountWithdrawalFormInfoSuspense'] : ['UserCreditAccountWithdrawalFormInfoSuspense', variables];


useUserCreditAccountWithdrawalFormInfoQuery.fetcher = (variables?: UserCreditAccountWithdrawalFormInfoQueryVariables, options?: RequestInit['headers']) => fetcher<UserCreditAccountWithdrawalFormInfoQuery, UserCreditAccountWithdrawalFormInfoQueryVariables>(UserCreditAccountWithdrawalFormInfoDocument, variables, options);

export const CreditAccountWithdrawalSettingsDocument = `
    query CreditAccountWithdrawalSettings($creditAccountId: Int!, $amount: Float!) {
  credit_account_withdrawal(credit_account_id: $creditAccountId, amount: $amount) {
    instant_payment_enabled
    instant_payment_fee
  }
}
    `;

export const useCreditAccountWithdrawalSettingsQuery = <
      TData = CreditAccountWithdrawalSettingsQuery,
      TError = unknown
    >(
      variables: CreditAccountWithdrawalSettingsQueryVariables,
      options?: Omit<UseQueryOptions<CreditAccountWithdrawalSettingsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CreditAccountWithdrawalSettingsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CreditAccountWithdrawalSettingsQuery, TError, TData>(
      {
    queryKey: ['CreditAccountWithdrawalSettings', variables],
    queryFn: fetcher<CreditAccountWithdrawalSettingsQuery, CreditAccountWithdrawalSettingsQueryVariables>(CreditAccountWithdrawalSettingsDocument, variables),
    ...options
  }
    )};

useCreditAccountWithdrawalSettingsQuery.getKey = (variables: CreditAccountWithdrawalSettingsQueryVariables) => ['CreditAccountWithdrawalSettings', variables];

export const useSuspenseCreditAccountWithdrawalSettingsQuery = <
      TData = CreditAccountWithdrawalSettingsQuery,
      TError = unknown
    >(
      variables: CreditAccountWithdrawalSettingsQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<CreditAccountWithdrawalSettingsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<CreditAccountWithdrawalSettingsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<CreditAccountWithdrawalSettingsQuery, TError, TData>(
      {
    queryKey: ['CreditAccountWithdrawalSettingsSuspense', variables],
    queryFn: fetcher<CreditAccountWithdrawalSettingsQuery, CreditAccountWithdrawalSettingsQueryVariables>(CreditAccountWithdrawalSettingsDocument, variables),
    ...options
  }
    )};

useSuspenseCreditAccountWithdrawalSettingsQuery.getKey = (variables: CreditAccountWithdrawalSettingsQueryVariables) => ['CreditAccountWithdrawalSettingsSuspense', variables];


useCreditAccountWithdrawalSettingsQuery.fetcher = (variables: CreditAccountWithdrawalSettingsQueryVariables, options?: RequestInit['headers']) => fetcher<CreditAccountWithdrawalSettingsQuery, CreditAccountWithdrawalSettingsQueryVariables>(CreditAccountWithdrawalSettingsDocument, variables, options);
