import { OLD_APP_ROUTE_NAME } from '@config/routes';
import { LoadingScreen } from '@entities/app';
import { $isAuthorized } from '@entities/user';
import { useAppConfig } from '@hooks/system/useAppConfig';
import { useEffectOnce } from '@hooks/utils';
import { Navigate, createFileRoute } from '@tanstack/react-router';
import { useUnit } from 'effector-react';
import { lazy } from 'react';

const HomePage = lazy(() => import('@pages/home'));

export const Route = createFileRoute('/')({
	component: () => {
		const isAuthorized = useUnit($isAuthorized);
		const { shouldRedirectToOldApplication } = useAppConfig();
		// TODO: remove after migration to new dashboard
		useEffectOnce(() => {
			if (isAuthorized && shouldRedirectToOldApplication) {
				window.open(OLD_APP_ROUTE_NAME.home, '_self');
			}
		});

		if (isAuthorized) {
			if (shouldRedirectToOldApplication) {
				return null;
			}

			return <Navigate to="/dashboard" replace />;
		}

		return <HomePage />;
	},
	pendingComponent: LoadingScreen,
});
