import { OldApplicationRedirectStatus } from '@/shared/types';
import { APP_CONFIG } from '@config/app';
import { REDIRECT_OLD_CP_STATUS } from '@config/envs';
import { $userId } from '@entities/user';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useAppConfig = () => {
	const { i18n } = useTranslation();
	const userId = useUnit($userId);

	// TODO: remove after migration to new dashboard
	const shouldRedirectToOldApplication = useMemo(() => {
		if (REDIRECT_OLD_CP_STATUS === OldApplicationRedirectStatus.INACTIVE) {
			return false;
		}

		if (REDIRECT_OLD_CP_STATUS === OldApplicationRedirectStatus.ACTIVE) {
			return true;
		}

		if (!userId) {
			return false;
		}

		return userId % 7 !== 0;
	}, [userId]);

	return useMemo(
		() => ({
			shouldRedirectToOldApplication,
			authMethods: APP_CONFIG.authMethods,
			starProduct: APP_CONFIG.starProduct,
			supportUrl: APP_CONFIG.supportUrlByLanguage[i18n.language],
			legalInfo: APP_CONFIG.legalInfo,
			termsUrl: APP_CONFIG.termsUrlByLanguage[i18n.language],
			phoneCode: APP_CONFIG.phoneCode,
			isRejectedCAWRedirectionToCamEnabled:
				APP_CONFIG.isRejectedCAWRedirectionToCamEnabled,
		}),
		[i18n.language, shouldRedirectToOldApplication],
	);
};
