import { SimpleEligibilityStatus } from '@/shared/types';
import { CreditAccountWithdrawalEligibilityState } from '@/shared/types/credit-account-withdrawal';
import { APP_CONFIG } from '@config/app';
import { MIN_USER_DISPOSABLE_INCOME } from '@config/credit-account-withdrawal';
import {
	OLD_APP_ROUTE_NAME,
	PURCHASE_FLOW_ROUTE_NAME,
	RouteName,
} from '@config/routes';
import { $user } from '@entities/user';
import { serverFetch } from '@lib/serverFetcher';
import { useUserCreditAccountWithdrawalSuccessInfoQuery } from '@pages/credit-account-withdrawal/success/api';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const CreditAccountWithdrawalSearch = z.object({
	fromPathname: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),
	error: z.boolean().optional(),
});

export const Route = createFileRoute('/_protected/credit-account-withdrawal')({
	validateSearch: CreditAccountWithdrawalSearch,
	loaderDeps: ({ search: { hash, fromPathname } }) => ({ hash, fromPathname }),
	loader: async ({ deps: { hash, fromPathname }, location }) => {
		if (!hash) {
			if (location.pathname !== RouteName.CREDIT_ACCOUNT_WITHDRAWAL_FORM) {
				throw redirect({
					replace: true,
					to: '/credit-account-withdrawal/form',
					search: {
						fromPathname,
					},
				});
			}

			return {
				type: undefined,
			};
		}

		try {
			const data = await serverFetch(
				useUserCreditAccountWithdrawalSuccessInfoQuery,
				{ variables: { hash } },
			);

			const { eligibility_state, type, simple_eligibility_status } =
				data.current_credit_account_withdrawal ?? {};

			if (!eligibility_state) {
				throw redirect({
					replace: true,
					to: '/credit-account-withdrawal/form',
				});
			}

			if (
				eligibility_state ===
					CreditAccountWithdrawalEligibilityState.NEGATIVE &&
				location.pathname !== RouteName.CREDIT_ACCOUNT_WITHDRAWAL_REJECT
			) {
				const disposableIncomeWithoutCa =
					$user.getState()?.disposable_income_without_ca;
				if (
					APP_CONFIG.isRejectedCAWRedirectionToCamEnabled &&
					simple_eligibility_status ===
						SimpleEligibilityStatus.DSTI_DSCR_INSUFFICIENT &&
					!!disposableIncomeWithoutCa &&
					disposableIncomeWithoutCa > MIN_USER_DISPOSABLE_INCOME
				) {
					window.open(OLD_APP_ROUTE_NAME.creditAccountModification, '_self');
					return {
						type,
					};
				}

				throw redirect({
					replace: true,
					to: '/credit-account-withdrawal/reject',
					search: {
						fromPathname,
					},
				});
			}

			if (
				eligibility_state === CreditAccountWithdrawalEligibilityState.SCORING
			) {
				window.open(
					PURCHASE_FLOW_ROUTE_NAME.creditLineWithdrawal.replace('$hash', hash),
					'_self',
				);
			}

			if (
				eligibility_state === CreditAccountWithdrawalEligibilityState.SUCCESS &&
				location.pathname !== RouteName.CREDIT_ACCOUNT_WITHDRAWAL_SUCCESS
			) {
				throw redirect({
					replace: true,
					to: '/credit-account-withdrawal/success',
				});
			}

			return {
				type,
			};
		} catch (error) {
			throw redirect({
				to: '/credit-account-withdrawal/form',
				replace: true,
			});
		}
	},
});
