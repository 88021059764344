import { routeTree } from '@/routeTree.gen';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Suspense } from 'react';

export const router = createRouter({
	routeTree,
	notFoundMode: 'root',
	defaultPreload: 'intent',
	defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}
export const AppRouter = () => {
	return (
		<Suspense>
			<RouterProvider router={router} />
		</Suspense>
	);
};
