import {
	googleAnalyticsModel,
	smartlookModel,
} from '@entities/analytics/model';
import {
	$isLocalizationInitialized,
	initLocalizationEv,
} from '@entities/languages';
import { $isAuthChecked, checkUserOnFirstLoadEv } from '@entities/user';
import { Sift } from '@lib/sift';
import { createEffect, createEvent, createStore, sample } from 'effector';

const routeChangedFx = createEffect(() => {
	Sift.trackPageView();
});

const initAppEv = createEvent('init app');
const routeChangedEv = createEvent('route changed');

const $isAppInitialized = createStore(false);

sample({
	clock: initAppEv,
	target: [
		checkUserOnFirstLoadEv,
		initLocalizationEv,
		// analytics
		smartlookModel.initEv,
		googleAnalyticsModel.initEv,
	],
});

sample({
	source: {
		isAuthChecked: $isAuthChecked,
		isLocalizationInitialized: $isLocalizationInitialized,
	},
	fn: ({ isAuthChecked, isLocalizationInitialized }) =>
		isAuthChecked && isLocalizationInitialized,
	target: $isAppInitialized,
});

sample({
	clock: routeChangedEv,
	target: routeChangedFx,
});

export { $isAppInitialized, initAppEv, routeChangedEv };
